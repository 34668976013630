export class Product {
  constructor ({ uuid = 0, notifyMaintenance = true } = {}) {
    this.uuid = uuid
    this.notify_maintenance = notifyMaintenance
  }
}

export function createProduct (data) {
  return Object.freeze(new Product(data))
}
