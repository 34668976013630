import Vue from 'vue'

import { ProductService } from '@/common/api.service'
import { createProduct } from '@/models/product'

import { PRODUCT_LIST, PRODUCT_GET, PRODUCT_SAVE_NOTIFY_TOGGLE, RAISE_GLOBAL_ERROR, RAISE_GLOBAL_SUCCESS } from '../action-types'
import {
  SET_PRODUCTS,
  SET_PRODUCT,
  RESET_STATE,
  SET_PRODUCT_NOTIFY_FLAG,
  SET_SHOW_DELETED
} from '../mutation-types'

const getters = {
  getProducts: (state, getters) => {
    return state.showDeleted ? getters.deletedProducts : getters.activeProducts
  },
  activeProducts: state => {
    return state.productList.filter(product => !product.deleted_at.Valid)
  },
  deletedProducts: state => {
    return state.productList.filter(product => product.deleted_at.Valid)
  },
  numberOfRepairWarnings: state => {
    return state.productList.filter((product) => product.is_repair_warning && !product.deleted_at.Valid).length
  }
}

const actions = {
  async [PRODUCT_LIST] ({ commit, dispatch }) {
    try {
      const { data } = await ProductService.list()
      commit(SET_PRODUCTS, data)
    } catch (error) {
      dispatch(RAISE_GLOBAL_ERROR, error.message, { root: true })
    }
  },
  async [PRODUCT_GET] ({ commit, dispatch }, productUUID) {
    try {
      const product = await ProductService.get(productUUID)
      commit(SET_PRODUCT, product.data)
    } catch (error) {
      dispatch(RAISE_GLOBAL_ERROR, error.message, { root: true })
    }
  },
  async [PRODUCT_SAVE_NOTIFY_TOGGLE] ({ state, dispatch }) {
    try {
      const product = createProduct({
        uuid: state.product.uuid,
        notifyMaintenance: state.product.notify_maintenance
      })

      await ProductService.setNotifyMaintenanceFlag(product)

      dispatch(RAISE_GLOBAL_SUCCESS, 'Produit enregistré avec succès', { root: true })
    } catch (error) {
      dispatch(RAISE_GLOBAL_ERROR, error.message, { root: true })
      throw error
    }
  }
}

const mutations = {
  [SET_PRODUCTS] (state, products) {
    state.productList = products
  },
  [SET_PRODUCT] (state, product) {
    state.product.uuid = product.uuid
    state.product.id = product.id
    state.product.description = product.description
    state.product.type = product.type
    state.product.deleted_at = product.deleted_at
    state.product.notify_maintenance = product.notify_maintenance
    state.product.is_repair_warning = product.is_repair_warning
    state.product.last_repair = product.last_repair
    state.product.total_hours_repair = product.total_hours_repair
    state.product.total_parts_repair = product.total_parts_repair
    state.product.repairs = product.repairs
  },
  [SET_PRODUCT_NOTIFY_FLAG] (state, flag) {
    state.product.notify_maintenance = flag
  },
  [RESET_STATE] () {
    for (const f in state) {
      Vue.set(state, f, initialState[f])
    }
  },
  [SET_SHOW_DELETED] (state, showDeleted) {
    state.showDeleted = showDeleted
  }
}

const initialState = {
  product: {
    uuid: '',
    id: '',
    description: '',
    type: '',
    deleted_at: '',
    notify_maintenance: '',
    is_repair_warning: '',
    last_repair: '',
    total_hours_repair: 0,
    total_parts_repair: 0,
    repairs: []
  },
  productList: [],
  showDeleted: false
}

const state = () => ({ ...initialState })

export const productStore = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
