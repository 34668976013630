<template>
  <b-modal v-model="isLoginModalActive" :width="640" :can-cancel="false">
    <form ref="loginForm" @submit.prevent="doLogin">
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">Connexion</p>
          <p>Maintenance Remorques Ami-Loue {{ version }}</p>
        </header>
        <section class="modal-card-body">
          <b-field label="Nom d'utilisateur">
            <b-input
              type="text"
              v-model="username"
              required>
            </b-input>
          </b-field>

          <b-field label="Mot de passe">
            <b-input
              type="password"
              v-model="password"
              password-reveal
              @keyup.enter.native="doLogin"
              required>
            </b-input>
          </b-field>

        </section>
        <footer class="modal-card-foot">
          <b-button
            @click="doLogin"
            label="Connexion"
            type="is-primary" />
        </footer>
      </div>
    </form>
  </b-modal>
</template>

<script>
import { LOGIN } from '@/store/action-types'
import { getVersion } from '@/common/version'

export default {
  data () {
    return {
      username: '',
      password: '',
      isLoginModalActive: true,
      version: getVersion()
    }
  },
  methods: {
    doLogin () {
      if (this.$refs.loginForm.checkValidity()) {
        this.$store
          .dispatch(LOGIN, { username: this.username, password: this.password })
          .then(() => this.$router.push(this.$route.query.to ? this.$route.query.to : '/'))
          .catch()
      }
    }
  }
}
</script>
