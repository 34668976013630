import Vue from 'vue'
import Vuex from 'vuex'

import { appStore } from '@/store/modules/app'
import { userStore } from '@/store/modules/user'
import { configStore } from '@/store/modules/config'
import { productStore } from '@/store/modules/products'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    appStore,
    userStore,
    configStore,
    productStore
  },
  strict: process.env.NODE_ENV !== 'production'
})
