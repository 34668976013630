<template>
  <div id="app">
    <NavBar v-if="isLoggedIn"></NavBar>
    <nav class="navbar is-warning has-text-centered" v-if="this.$store.state.configStore.environment === 'debug'">
      <p style="margin: auto; width: 50%; font-weight: bold">DEVELOPMENT - DEVELOPMENT - DEVELOPMENT- DEVELOPMENT - DEVELOPMENT </p>
    </nav>
    <SideBar v-if="isLoggedIn"></SideBar>

    <section class="content main">
      <router-view/>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import SideBar from '@/components/SideBar'
import NavBar from '@/components/NavBar'

export default {
  components: {
    SideBar,
    NavBar
  },
  computed: {
    ...mapState({
      isLoggedIn: state => state.userStore.isAuthenticated
    })
  }
}

</script>

<style lang="scss">
@import "@/style/style";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //text-align: center;
  color: #2c3e50;
}

.main {
  @include desktop {
    margin-left: $sidebar-width;
  }
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
