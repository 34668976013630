import Vue from 'vue'
import Buefy from 'buefy'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'

import App from './App.vue'
import router from './router'
import store from './store'

import ApiService from '@/common/api.service'

Vue.config.productionTip = false
Vue.use(Buefy)

ApiService.init()

Sentry.init({
  Vue,
  dsn: 'https://6651b7b935ad427d980c008d24875313@o383764.ingest.sentry.io/5214142',
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  logErrors: true,
  release: process.env.VUE_APP_GIT_HASH,
  environment: process.env.NODE_ENV
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
