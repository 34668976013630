<template>
  <div class="sidebar-page">
    <section class="sidebar-layout">
      <b-sidebar
          position="fixed"
          mobile="hide"
          :expand-on-hover="expandOnHover"
          :reduce="reduce"
          type="is-light"
          open
          fullheight
      >
        <div class="p-1">
          <div class="block">
            <img alt="Vue logo" src="../assets/logo-remorquesamiloue.png">
          </div>
          <b-menu class="is-custom-mobile" :accordion="false">
            <b-menu-list label="Menu">
              <b-menu-item icon="information-outline" label="Info" tag="router-link" to="/" exact-active-class="is-active"></b-menu-item>
              <b-menu-item icon="tow-truck" tag="router-link" to="/products" exact-active-class="is-active">
                <template #label>
                  Remorques
                  <b-icon class="is-pulled-right" :icon="getRemorquesNotificationIconClass" type="is-danger"></b-icon>
                </template>
              </b-menu-item>
              <b-menu-item expanded icon="ship-wheel" label="Paramètres" tag="router-link" :to="{ name: 'Settings' }" exact-active-class="is-active">
                <b-menu-item icon="account" label="Utilisateur" tag="router-link" :to="{ name: 'User' }" exact-active-class="is-active"></b-menu-item>
              </b-menu-item>
            </b-menu-list>
            <b-menu-list label="Actions">
              <b-menu-item icon="logout" label="Déconnexion" @click="doLogout"></b-menu-item>
            </b-menu-list>
          </b-menu>
        </div>
        <p style="text-align: center;"><small>{{ version }}</small></p>
      </b-sidebar>
    </section>
  </div>
</template>

<script>
import { LOGOUT } from '@/store/action-types'
import { getVersion } from '@/common/version'

export default {
  data () {
    return {
      expandOnHover: false,
      reduce: false,
      version: getVersion()
    }
  },
  computed: {
    remorquesNotifications () {
      return this.$store.getters['productStore/numberOfRepairWarnings']
    },
    getRemorquesNotificationIconClass () {
      if (this.remorquesNotifications === 0) {
        return ''
      } else if (this.remorquesNotifications < 10) {
        return 'numeric-' + this.remorquesNotifications + '-box'
      } else {
        return 'numeric-9-plus-box'
      }
    }
  },
  methods: {
    doLogout () {
      this.$store.dispatch(LOGOUT).then(() => this.$router.push({ name: 'Login' }))
    }
  }
}
</script>

<style lang="scss">
.p-1 {
  padding: 1em;
}
.sidebar-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  // min-height: 100vh;
  .sidebar-layout {
    display: flex;
    flex-direction: row;
    min-height: 100%;
    // min-height: 100vh;
  }
}
</style>
