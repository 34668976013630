import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'

import { RAISE_GLOBAL_ERROR, SET_USER_LOGGED_OUT } from '@/store/action-types'
import store from '@/store/index'
// eslint-disable-next-line no-unused-vars
import router from '@/router/index'

export const ApiService = {
  init () {
    Vue.use(VueAxios, axios)
    Vue.axios.defaults.baseURL = '/api/arm/'
    axios.defaults.headers.common.Accept = 'application/json'
    Vue.axios.defaults.withCredentials = true
    this.setErrorInterceptor()
  },
  setErrorInterceptor () {
    axios.interceptors.response.use(
      response => {
        return response
      },
      error => {
        if (error.response.status === 401) {
          store.dispatch(SET_USER_LOGGED_OUT)
          if (error.config.url !== 'user/current') {
            router.replace({
              path: '/login',
              query: { to: router.currentRoute.path }
            })
          }
          return Promise.reject(error)
        } else if (!error.response) {
          error.response = { data: { errors: 'Erreur réseau: impossible de communiquer avec le serveur' } }
          console.error(error.response)
        }
        console.error(error.response)
        store.dispatch(RAISE_GLOBAL_ERROR, error.response)
        return Promise.reject(error)
      }
    )
  },
  get (uri, params) {
    return Vue.axios.get(uri, params)
  },
  post (uri, data) {
    return Vue.axios.post(uri, data)
  },
  put (uri, data) {
    return Vue.axios.put(uri, data)
  }
}

export default ApiService

export const RepairService = {
  add (repair) {
    return ApiService.post('products/rental/newRepair', repair)
  }
}

export const ConfigService = {
  get () {
    return ApiService.get('config')
  }
}

export const ProductService = {
  list () {
    return ApiService.get('products/rental')
  },
  get (productUUID) {
    return ApiService.get(`products/rental/byUUID/${productUUID}`)
  },
  setNotifyMaintenanceFlag (product) {
    return ApiService.put(`products/rental/byUUID/${product.uuid}`, product)
  }
}

export const UserService = {
  login (user) {
    const bodyFormData = new FormData()
    bodyFormData.append('username', user.username)
    bodyFormData.append('password', user.password)
    return ApiService.post('login', bodyFormData)
  },
  logout () {
    return ApiService.get('logout')
  },
  getCurrentUser () {
    return ApiService.get('user/current')
  },
  updatePassword (userChangePass) {
    const bodyFormData = new FormData()
    bodyFormData.append('nouveauMDP', userChangePass.password)
    bodyFormData.append('confirmationMDP', userChangePass.passwordConfirmation)
    return ApiService.post('user/settings/password', bodyFormData)
  }
}
