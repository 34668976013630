import { ConfigService } from '@/common/api.service'

import { GET_APP_CONFIG } from '@/store/action-types'
import { SET_GLOBAL_ERROR, SET_APP_CONFIG } from '@/store/mutation-types'

const initialState = {
  environment: '',
  categories: []
}

export const configStore = {
  state: () => ({ ...initialState }),
  actions: {
    async [GET_APP_CONFIG] ({ commit }) {
      try {
        const { data } = await ConfigService.get()
        commit(SET_APP_CONFIG, data)
      } catch (error) {
        commit(SET_GLOBAL_ERROR, error.message)
      }
    }
  },
  mutations: {
    [SET_APP_CONFIG] (state, config) {
      state.environment = config.environment
      state.categories = config.categories
    }
  }
}
