// eslint-disable-next-line import/prefer-default-export
export const LOGIN = 'login'
export const LOGOUT = 'logout'
export const GET_CURRENT_USER = 'getCurrentUser'
export const USER_CHANGE_PASSWORD = 'userChangePassword'
export const SET_USER_LOGGED_OUT = 'setUserLoggedOut'

export const GET_APP_CONFIG = 'getAppConfig'

export const RAISE_GLOBAL_ERROR = 'raiseGlobalError'
export const RAISE_GLOBAL_SUCCESS = 'raiseGlobalSuccess'

export const PRODUCT_SAVE_NOTIFY_TOGGLE = 'productSaveNotifyToggle'
export const PRODUCT_LIST = 'productList'
export const PRODUCT_GET = 'productGet'
