import * as Sentry from '@sentry/vue'

import { UserService } from '@/common/api.service'
import { createUser } from '@/models/user'

import {
  LOGIN,
  LOGOUT,
  GET_CURRENT_USER,
  USER_CHANGE_PASSWORD,
  SET_USER_LOGGED_OUT,
  GET_APP_CONFIG, PRODUCT_LIST
} from '../action-types'
import {
  SET_AUTH,
  PURGE_AUTH,
  SET_GLOBAL_ERROR
} from '../mutation-types'

function onLoginOrPageLoad (dispatch, state) {
  Sentry.setUser({ id: state.id, username: state.username, role: state.role })
  dispatch(GET_APP_CONFIG)
  dispatch('productStore/' + PRODUCT_LIST)
}

const state = () => ({
  isAuthenticated: null,
  id: '',
  username: '',
  name: '',
  role: ''
})

const getters = {
  currentUser (state) {
    return state.name
  },
  isAuthenticated (state) {
    return state.isAuthenticated
  },
  isAdmin (state) {
    return state.role === 'admin'
  }
}

const actions = {
  async [LOGIN] ({ commit, dispatch }, { username, password }) {
    try {
      const user = createUser({
        username,
        password
      })
      await UserService.login(user)
      await dispatch(GET_CURRENT_USER)
    } catch (error) {
      commit(SET_GLOBAL_ERROR, error.message, { root: true })
    }
  },
  async [LOGOUT] ({ commit }) {
    try {
      await UserService.logout()

      commit(PURGE_AUTH)
      Sentry.configureScope(scope => scope.setUser(null))
    } catch (error) {
      commit(SET_GLOBAL_ERROR, error.message, { root: true })
    }
  },
  async [GET_CURRENT_USER] ({ commit, state, dispatch }) {
    try {
      if (!state.isAuthenticated) {
        const { data } = await UserService.getCurrentUser()
        commit(SET_AUTH, data)
        onLoginOrPageLoad(dispatch, state)
      }
    } catch (error) {
      commit(PURGE_AUTH)
    }
  },
  async [USER_CHANGE_PASSWORD] ({ commit }, newPass) {
    try {
      await UserService.updatePassword(newPass)
    } catch (error) {
      commit(SET_GLOBAL_ERROR, error.message, { root: true })
    }
  },
  [SET_USER_LOGGED_OUT] ({ commit }) {
    commit(PURGE_AUTH)
  }
}

const mutations = {
  [SET_AUTH] (state, data) {
    state.isAuthenticated = true

    state.id = data.id
    state.username = data.username
    state.name = data.name
    state.role = data.role
  },
  [PURGE_AUTH] (state) {
    state.isAuthenticated = false

    state.id = ''
    state.username = ''
    state.name = ''
    state.role = ''
  }
}

export const userStore = {
  getters,
  actions,
  mutations,
  state
}
