export class User {
  constructor ({ username = '', password = '' } = {}) {
    this.username = username
    this.password = password
  }
}

export function createUser (data) {
  return Object.freeze(new User(data))
}

export class UserChangePassword {
  constructor ({ password = '', passwordConfirmation = '' } = {}) {
    this.password = password
    this.passwordConfirmation = passwordConfirmation
  }
}

export function createUserChangePassword (data) {
  return Object.freeze(new UserChangePassword(data))
}
