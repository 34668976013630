<template>
  <b-navbar class="navbar-visibility">
    <template #brand>
      <b-navbar-item tag="router-link" :to="{ path: '/' }">
        <img
          src="../assets/logo-remorquesamiloue.png"
          alt="SGL Maintenance Remorques Ami-Loue"
        />
      </b-navbar-item>
    </template>
    <template #start>
      <b-navbar-item icon="information-outline" tag="router-link" to="/" exact-active-class="is-active">Info</b-navbar-item>
      <b-navbar-item icon="tow-truck" tag="router-link" to="/products" exact-active-class="is-active"> Remorques <b-icon class="is-pulled-right" :icon="getRemorquesNotificationIconClass" type="is-danger"></b-icon></b-navbar-item>
      <b-navbar-dropdown label="Paramètres">
        <b-navbar-item  icon="account" label="Utilisateur" tag="router-link" :to="{ name: 'User' }" exact-active-class="is-active"> Utilisateur </b-navbar-item>
      </b-navbar-dropdown>
    </template>

    <template #end>
      <b-navbar-item tag="div">
        <div class="buttons">
          <a class="button is-light" @click="doLogout"> Déconnexion </a>
          <p><small>{{ version }}</small></p>
        </div>
      </b-navbar-item>
    </template>
  </b-navbar>
</template>
<script>
import { LOGOUT } from '@/store/action-types'
import { getVersion } from '@/common/version'

export default {
  data () {
    return {
      version: getVersion()
    }
  },
  computed: {
    remorquesNotifications () {
      return this.$store.getters['productStore/numberOfRepairWarnings']
    },
    getRemorquesNotificationIconClass () {
      if (this.remorquesNotifications === 0) {
        return ''
      } else if (this.remorquesNotifications < 10) {
        return 'numeric-' + this.remorquesNotifications + '-box'
      } else {
        return 'numeric-9-plus-box'
      }
    }
  },
  methods: {
    doLogout () {
      this.$store.dispatch(LOGOUT).then(() => this.$router.push({ name: 'Login' }))
    }
  }
}
</script>
<style lang="scss">
@import "@/style/style";

.navbar-visibility {
  @include touch {
    visibility: visible;
  }
  @include desktop {
    visibility: hidden;
  }
}
</style>
