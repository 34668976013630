import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import Login from '@/components/Login'

import store from '@/store'
import { GET_CURRENT_USER } from '@/store/action-types'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      loginPage: true
    }
  },
  {
    path: '/products',
    name: 'Products',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "products" */ '../views/Products.vue')
  },
  {
    path: '/products/:id',
    name: 'singleProduct',
    component: () => import(/* webpackChunkName: "products" */ '../views/Product.vue')
  },
  {
    path: '/settings/user',
    name: 'User',
    component: () => import(/* webpackChunkName: "users" */ '../views/Settings/User.vue')
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import(/* webpackChunkName: "users" */ '../views/Settings.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return savedPosition || { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  store.dispatch(GET_CURRENT_USER).then(() => {
    if (to.matched.some(record => record.meta.loginPage)) {
      if (store.state.userStore.isAuthenticated) {
        next('/')
        next(to.query.to ? to.query.to : '/')
      } else {
        next()
      }
    } else if (!store.state.userStore.isAuthenticated) {
      next({
        path: '/login',
        query: { to: to.path }
      })
    } else {
      next()
    }
  }).catch(() => {
    next({
      path: '/login',
      query: { to: to.path }
    })
  })
})

export default router
