<template>
  <div class="home">
    <img alt="Trailer" src="../assets/trailer-icon.svg">
    <h1>Bonjour {{ user }}!</h1>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Home',
  computed: {
    ...mapState({
      user: state => state.userStore.name
    })
  }
}
</script>
<style scoped lang="scss">
.home {
  text-align: center;
}
</style>
