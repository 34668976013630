import { NotificationProgrammatic } from 'buefy'

import { RAISE_GLOBAL_ERROR, RAISE_GLOBAL_SUCCESS } from '../action-types'
import { SET_GLOBAL_ERROR, CLEAR_GLOBAL_ERROR, SET_GLOBAL_SUCCESS } from '../mutation-types'

function showErrorNotification () {
  const notif = NotificationProgrammatic.open({
    indefinite: true,
    message: state.error,
    type: 'is-danger',
    hasIcon: true
  })
  notif.$on('close', () => {
    // NotificationProgrammatic.open('Custom notification closed!')
  })
}

function showSuccessNotification () {
  const notif = NotificationProgrammatic.open({
    message: state.success,
    type: 'is-success',
    hasIcon: true
  })
  notif.$on('close', () => {
  })
}

const state = {
  error: '',
  showError: false,

  success: 'Succès',
  showSuccess: false
}

const getters = {
}

const actions = {
  [RAISE_GLOBAL_ERROR] ({ commit }, error) {
    if (typeof error === 'string') {
      commit(SET_GLOBAL_ERROR, error)
    } else if (error.data !== undefined) {
      if (typeof error.data === 'string') {
        commit(SET_GLOBAL_ERROR, error.data)
      } else {
        if (error.data.errors !== undefined) {
          if (typeof error.data.errors === 'string') {
            commit(SET_GLOBAL_ERROR, error.data.errors)
          } else {
            commit(SET_GLOBAL_ERROR, JSON.stringify(error.data.errors))
          }
        } else {
          commit(SET_GLOBAL_ERROR, JSON.stringify(error.data))
        }
      }
    }
    showErrorNotification()
  },
  [RAISE_GLOBAL_SUCCESS] ({ commit }, success) {
    switch (typeof success) {
      case 'string':
        commit(SET_GLOBAL_SUCCESS, success)
        break
      default:
        commit(SET_GLOBAL_SUCCESS, JSON.stringify(success))
    }
    showSuccessNotification()
  }
}

const mutations = {
  [SET_GLOBAL_ERROR] (state, error) {
    state.error = error
    state.showError = true
  },
  [CLEAR_GLOBAL_ERROR] (state) {
    state.error = ''
    state.showError = false
  },
  [SET_GLOBAL_SUCCESS] (state, success) {
    state.success = success
    state.showSuccess = true
  }
}

export const appStore = {
  state,
  actions,
  mutations,
  getters
}
