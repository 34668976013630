export const RESET_STATE = 'resetState'

export const SET_GLOBAL_SUCCESS = 'setGlobalSuccess'
export const SET_GLOBAL_ERROR = 'setGlobalError'
export const CLEAR_GLOBAL_ERROR = 'clearGlobalError'

export const SET_AUTH = 'setAuth'
export const PURGE_AUTH = 'purgeAuth'

export const SET_APP_CONFIG = 'setAppConfig'

export const SET_PRODUCT = 'setProduct'
export const SET_PRODUCT_NOTIFY_FLAG = 'setProductNotifyFlag'
export const SET_PRODUCTS = 'setProducts'
export const SET_SHOW_DELETED = 'setShowDeleted'
